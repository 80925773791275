<template>
  <div class="header-container">
    <h1>{{content.page.title}}</h1>
  </div>
  <div class="container" v-html="content.page.mainContent">

  </div>
</template>

<script>

export default{
  name:'Competition',
  props:['initData','contents','pageId'],
  data(){

    return{}
  },
  computed:{
    language(){
      return this.initData.init.language
    },
    content(){
      let contents = this.contents[this.pageId]

      this.initData.init.head.pageId = this.pageId
      this.initData.init.head.title = contents.head.title
      this.initData.init.head.keywords = contents.head.keywords
      this.initData.init.head.description = contents.head.description

      return contents
    }
  }
}
</script>
<style scoped>
div {
  margin: 0 auto;
  padding: 0;
}

img{
  width: 100%;
  height: 100%;
}

.container {
  max-width: 1064px;
  margin-bottom: 7rem;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 9rem;
  background-image: linear-gradient(to right, rgba(119, 132, 16, .8), rgba(119, 132, 16, .8)), url("@/assets/img/background.png");
  mix-blend-mode: normal;
}

.header-container h1 {
  color: var(--white);
  font-size: 3rem;
  font-weight: 300;
}

.container:deep(h2) {
  font-style: normal;
  font-weight: 300;
  font-size: 2.5rem;
  line-height: 3rem;
  margin-top: 5rem;

}

.container:deep(p) {
  margin: 2.5rem 0;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5rem;
}

.container:deep(.legend) {
  background-color: var(--gray);
  width: 100%;
  padding: 1.5rem;
}

.container:deep(.legend p) {
  margin: 0;
  padding: 0;
}

.container:deep(.accent) {
  color: var(--dark-green);
  font-weight: 400;
}

.container:deep(ul) {
  margin-top: 2rem;
  margin-bottom: 3rem;
  line-height: 2.5rem;
}

.container:deep(h3) {
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin-bottom: 2.75rem;
}

.container:deep(table) {
  width: 100%;
  border-collapse: collapse;
}

.container:deep(table thead th) {
  border-bottom: 1px solid var(--dark-gray);
}

.container:deep(table thead tr th) {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 2rem;

  letter-spacing: 1px;
}

.container:deep(table tbody td) {
  border-bottom: 1px solid var(--gray);
}

.container:deep(table tbody tr td) {
  font-style: normal;
  font-weight: 300;
  line-height: 3rem;
}

@media (max-width: 768px){
  .container {
    padding: 0 1rem;
  }
}
</style>